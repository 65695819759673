.scrolling-gallery-container {
  width: 100%;
  overflow: hidden;
}

.scrolling-gallery {
  height: 200px;
  width: 7100px;
}
.scrolling-gallery img {
  height: 200px;
  margin-right: -5px;
}

.initiate-scroll {
  animation: galleryscroll 36s linear infinite;
}

@keyframes galleryscroll {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2925px;
  }
}

.scrolling-marquee {
  height: 115px;
  width: 2600px;
}
.scrolling-marquee img {
  margin-right: -5px;
  margin-right: 50px;
  width: 100px;
}

.initiate-marquee {
  animation: marqueescroll 30s linear infinite;
}

@keyframes marqueescroll {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -1800px;
  }
}

h4 {
  font-family: alegreya;
  font-style: italic;
  color: #ffb200;
  text-shadow: 1px 0px black;
  font-size: calc(1.75rem + 0.3vw) !important;
}
