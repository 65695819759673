.service-display {
  --s: 150px;
  display: grid;
  width: var(--s);
  aspect-ratio: 1;
  border-radius: 50%;
  bottom: -30px;
  overflow: hidden;
  border: 5px solid #03a5b6;
  box-shadow: 0px 0px 15px 3px #004ea8b9;
}

@media (min-width: 800px) {
  .service-display {
    right: calc(100vh / 6);
  }
}

@media (max-width: 800px) {
  .service-display {
    display: none;
  }
}

.service-display img {
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  transform-origin: 50% 120.7%;
  animation: m 8s infinite cubic-bezier(0.5, -0.2, 0.5, 1.2);
}
.service-display .third {
  animation-delay: 2s;
}
.service-display .second {
  animation-delay: 4s;
}
.service-display .first {
  animation-delay: 6s;
}

.imglink {
  cursor: pointer;
}

@keyframes m {
  0%,
  3% {
    transform: rotate(0);
  }
  22%,
  27% {
    transform: rotate(-90deg);
  }
  47%,
  52% {
    transform: rotate(-180deg);
  }
  72%,
  77% {
    transform: rotate(-270deg);
  }
  98%,
  100% {
    transform: rotate(-360deg);
  }
}
