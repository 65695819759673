.float-start {
  width: 100px;
  margin-top: -13px;
  border-radius: 50%;
  background-color: #03a4b6;
}

.logo {
  max-height: 50px;
  margin-top: 10px;
}

.navbar {
  background-color: #03a4b6;
  display: flex;
  justify-content: center;
  z-index: 2000;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px;
  align-items: center;
  list-style-type: none;
  font-weight: bold;
  font-family: alegreya;
  flex: 1;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navitems{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 800px;
  margin-right: 10%;
}

@media only screen and (max-width: 600px) {
  .nav-link {
    margin: -13px;
  }

  .navbar {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.nav-link {
  font-size: 20px;
  text-decoration: none;
}

.nav-link:hover {
  color: #ffcf00;
}

.nav-link:link {
  color: white;
}
.nav-link:visited {
  color: rgb(210, 242, 247);
}

.nav-link:active {
  color: #ffb200;
}