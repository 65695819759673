.quote-headline{
    color:#ffb200;
    font-size: 3rem;
    font-family: modak;
}

.quote-section{
    display: flex;
    flex-direction: column;
    align-items:start;
}

.quote-row{
display: flex;
width: 100%;
}

.quote-header{
    font-family: alegreya;
    color:#ffb200;
}

.quote-text{
    font-family: alegreya;
    color: black;
}

.quote-icon{
    height: 35px;
    width: auto;
    
}

.quote-file-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.quote-add-another-button {
    padding: 5px;
    color: white;
    background-color: #03a5b6;
    width: fit-content;
    border-radius: 5px;
    margin-top: 2px;
}

.quote-date{
    margin-bottom: 5px;
}

.quote-add-on-section{
    border: 3px solid #03a5b6;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.quote-add-on-header{
    font-family: alegreya;
    color: #03a5b6;
}

.quote-submit{
    border: #ffb200 2px solid;
    background-color: #03a5b6;
    color: white;
    font-family: alegreya;
    padding: 10px;
    border-radius: 5px;
    font-size: large;
}

.quote-submit-container{
    display: flex;
    justify-content: center;
}