.gallery-img {
  width: 30vw;
  aspect-ratio: 1/1;
  margin: 1px;
}

.gallery-row {
  display: flex;
  justify-content: center;
}
.aboutus-section{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 30px;
}
.aboutus-header{
  color: #ffb200;
  font-family: alegreya;
  font-size: 1.5rem;
  text-align: center;
}

.aboutus-text{
  color: black;
  font-family: alegreya;
  font-size: 1rem;
}

.environmental-policy{
  background-color: green;
  width: 75%;
  border-radius: 15px;
  padding: 10px;
  font-family: alegreya;
  font-size: .75rem;
}