

.outline{
  border: 1px solid green;
}

.navigationvbar{
  position: fixed;
  top: 0px;
  z-index: 2000;
}