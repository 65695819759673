.testimonial-form {
  background-color: #ffb200;
  border-radius: 10px;
  padding: 20px;
  margin-left: 10%;
  margin-top: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
}

.thankyou-container{
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.testimonial-thankyou{
  max-width: 500px;
  text-align: center;
}
.testimonial-thankyou > h2{
  font-family: alegreya;
  text-align: center;
}

.p-black{
  color: black;
 
}

.name-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}

.name-inputs input {
  width: 100%;
}

.name-rating-testimonial {
  display: flex;
}

.rating-select {
  align-self: flex-start;
  margin-left: 25%;
  width: 25%;
}

.testimonial-card {
  background-color: #03a5b6;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 250px;
  display: inline-block;
  flex-wrap: wrap;
}
.submit-button {
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 10px;
  color: white;
  padding: 10px;
  margin-top: 10px;
  cursor: not-allowed;
  width: 20%;
  align-self: flex-end;
}

.submit-button.active {
  background-color: #03a5b6;
  border: 3px solid #ffcf00;
  cursor: pointer;
}

.testimonial-display{
  width: 80%;
  margin-left: 10%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}