
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardtitle {
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  font-weight: bold;
  margin-top: -15px;
  
}

.carousel {
  max-width: 200px;
  position: absolute;
  top: -90px;
  left: calc(100vh / 12);
}

p{
  font-family: alegreya;
  font-size: 14px;
  color: white;
  font-weight: bold;
}