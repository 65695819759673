.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  padding-top: 0px;
  font-family: alegreya;
}

.pricing-container {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .pricing-container {
    flex-direction: column;
  }
}
.pricing {
  border: 1px solid #03a5b6;
  width: 300px;
  margin: 0px 5px;
  display: flex;
  flex-direction: column;
}
.pricing > h4 {
  align-self: center;
}
.price-row {
  display: flex;
  min-width: 150px;
}
.price-row > p {
  color: black;
}
.price {
  margin-left: 5px;
  font-size: 1.1em;
}
.pricing > p {
  color: black;
  margin: 2px;
}
.black > p {
  color: black;
}
.column-img {
  max-width: 120px;
  height: 120px;
  border-radius: 50%;
  float: right;
}
.disclaimer {
  color: black;
  font-size: 0.75em;
}

.mobile-message {
  display: none;
  color: black;
  font-weight: 400;
}

.service-card {
  border-radius: 10px;
  border: 2px solid #03a5b6;
  padding: 10px;
  max-width: 30rem;
  margin-top: 20px;
  background-color: white;
}

.service-description {
  color: black;
  font-size: 1.2rem;
}

/* .service-card-section{
  display: flex;
  align-items: center;
} */

.service-img-float-left {
  float: left;
}
.service-img-float-right {
  float: right;
}

.full-width-div {
  display: flex;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    #03a5b6,
    white,
    white,
    white,
    white,
    #03a5b6
  );
}

@media only screen and (max-width: 768px) {
  .side-gallery {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .side-gallery-img {
    width: 150px;

    border-radius: 50%;
  }
}
@media only screen and (min-width: 768px) {
  .side-gallery {
    display: none;
  }
  .side-gallery-img {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .page-content {
    padding-left: 30px;
  }
  .mobile-message {
    display: inline;
  }
  .full-width-div {
    flex-direction: column;
  }
  .side-gallery {
    flex-direction: row;
  }
  .side-gallery-left {
    order: 3;
  }
  .side-gallery-img {
    width: 100px;
  }
}

.below-form {
  display: flex;
  justify-content: flex-end;
}

.below-form > p{
  color: black;
  font-size: large;
}

