.service-row {
  margin: 0px 1em;
  padding: 10px;
  font-family: alegreya;
  width: 100%;
  border-radius: 15px;
  background-color: white;
}
.service-row p {
  color: white;
}
.service-row h6 {
  font-size: 25px;
}

.service-img {
  border-radius: 50%;
  width: 100%;
  max-height: 250px;
  max-width: 250px;
}

.service-text {
  max-width: 100px;
}

.ser-yel {
  align-items: center;
  justify-content: center;
  border: 2px solid #ffcf00;
}
.ser-yel h6 {
  color: #ffcf00;
}
.ser-yel img {
  border: 5px solid #ffcf00;
}
.ser-yel a {
  color: #ffcf00;
}
.ser-yel a:hover {
  color: #ffb200;
}
.ser-yel p {
  color: black;
}

.ser-or {
  align-items: center;
  justify-content: center;
  border: 2px solid #ffb200;
}
.ser-or h6 {
  color: #ffb200;
}
.ser-or img {
  border: 5px solid #ffb200;
}
.ser-or a {
  color: #ffb200;
}
.ser-or p {
  color: black;
}
.ser-or a:hover {
  color: #ffcf00;
}

.ser-teal {
  align-items: center;
  justify-content: center;
  text-align: right;
  border: 2px solid #03a5b6;
}
.ser-teal h6 {
  color: #03a5b6;
}
.ser-teal img {
  border: 5px solid #03a5b6;
}
.ser-teal a {
  color: #03a5b6;
}
.ser-teal p {
  color: black;
}
.ser-teal a:hover {
  color: #04d3eaf4;
}

.service-div-width {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  padding-top: 0px;
  font-family: alegreya;
  max-width: 800px;
}

.homepage-service-header {
  color: #ffb200;
  font-family: alegreya;
  text-shadow: 1px 1px black;
  z-index: 2;
  font-style: italic;
  font-size: 2rem;
  text-align: center;
}

.specialty-img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.specialty-img {
  border-radius: 50%;
  max-width: 170px;
  max-height: 170px;
  border: 2px solid #03a5b6;
}

@media (max-width: 800px) {
  .specialty-img {
    max-width: 130px;
    max-height: 130px;
  }
  .service-img {
    max-height: 150px;
    max-width: 150px;
  }
}
