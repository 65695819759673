.twisting-p {
  color: black;
  font-family: alegreya;
}

.twisting-span {
  color: #03a5b6;
}

.twisting-label {
  color: #ffb200;
  font-size: 1rem;
  font-weight: 700;
  font-family: alegreya;
  text-align: left;
  width: 100%;
  margin-top: 10px;
}

.twisting-h2 {
  font-family: alegreya;
  font-size: 2rem;
  color: #03a5b6;
}

.container {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-container {
  display: flex;
  flex-direction: column;
  min-height: 500px;
}

.required {
  color: red;
}

.require {
  font-size: 0.8em;
  justify-self: left;
  font-style: italic;
}

.form-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  border: 3px solid #ffb200;
  border-radius: 10%;
  background-color: white;
  padding: 20px;
  min-height: 500px;
}



.range-display {
  margin-left: 20px;
  font-size: 2rem;
  color: #ffb200;
}

.additional-info-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  border: 3px solid #03a5b6;
  width: 500px;
  padding: 20px;
}

.form-div input {
  width: 100%;
}

.additional-info-div input {
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  max-height: fit-content;
  width: 100%;
}

.form-nav-button {
  padding: 5px;
  border: 3px solid #ffcf00;
  color: #03a5b6;
  font-weight: 600;
  border-radius: 10%;
  background-color: white;
  margin: 20px;
}

.twisting-submit-button {
  padding: 5px;
  border: 3px solid #ffcf00;
  color: white;
  font-weight: 600;
  border-radius: 10%;
  background-color: #03a5b6;
  margin: 20px;
}

.twisting-invalid-button {
  padding: 5px;
  border: 3px solid darkgray;
  color: black;
  font-weight: 600;
  border-radius: 10%;
  background-color: grey;
  margin: 20px;
}

.info-display {
  margin-left: 30px;
}

.info-display-div {
  width: 500px;
}

.twisting-tc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tc-span {
  text-decoration: underline;
  color: #03a5b6;
}

.modal-content {
  z-index: 10001;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  overflow-y: scroll;
  margin-top: 25px;
  align-items: center;
}

.faq-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  background-color: #03a5b6;
  margin-top: 10px;
}

.close-button {
  border: 3px solid #ffcf00;
  border-radius: 3px;
  background-color: white;
  color: #03a5b6;
  font-weight: 600;
  padding: 5px;
  max-width: 100px;
}

.twisting-form-row {
  display: flex;
}
.twisting-form-img {
  width: 170px;
  aspect-ratio: 1/1;
}

.add-on-container {
  position: relative;
}

.add-on-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
  font-size: 0.75em;
  font-family: alegreya;
}

.add-on-container:hover .add-on-overlay {
  opacity: 1;
}

.add-on-learn-more {
  color: white;
  border: 2px solid white;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  margin: 2px;
  border-radius: 5px;
}

.add-on-book-this {
  color: #fff;
  background-color: #03a5b6;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.color-row {
  display: flex;
  width: 100%;
}

.twisting-form-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.twisting-form-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.twisting-form-div input {
  width: 100%;
}


@media only screen and (max-width: 500px) {
  .form-div {
    width: 320px;
  }
  .additional-info-div {
    width: 320px;
  }
  .twisting-form-img {
    width: 150px;
  }
  .add-on-overlay {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.3);

  }
  .add-on-button-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .numguests-range{
    width: 300px;
  }
  .info-display-div {
    width: 100%;
  }
  .faq-content {
   
    width: 100%;
    
  }
}