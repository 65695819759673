/* fab.css opacity */
.fab {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 128 128"%3E%3Cpath d="M75.87 102.1c3 4.9-2.38 10.12 1.85 13.91c7.84 7 17.44-12.05 29.49 7.49" fill="none" stroke="%2364b5f6" stroke-width="3" stroke-miterlimit="10" %3E%3C/path%3E%3Cpath d="M74.5 106.88l5.63-1a2.86 2.86 0 0 0 2.07-4.1a34.64 34.64 0 0 0-7.2-9.62c-.44 2.89-2.16 7.38-3.62 10.8a2.85 2.85 0 0 0 3.12 3.92z" fill="%23f44336"%3E%3C/path%3E%3Cpath d="M25.56 51.54C33.14 83.3 64.22 97.75 75.68 95s32.1-32 25.08-61.43C95.79 12.8 74.93 0 54.16 4.97s-33.57 25.83-28.6 46.6v-.03z" fill="%23f44336"%3E%3C/path%3E%3Cpath d="M73.43 98.11l5.16-1.23c1.63-.39 2.64-2.02 2.25-3.65s-2.02-2.64-3.65-2.25L72 92.21c-1.63.39-2.64 2.02-2.25 3.65s2.02 2.64 3.65 2.25h.03z" fill="%23c62828"%3E%3C/path%3E%3Cpath d="M78.28 13.44c-4.07-2.48-9.9-4.13-13.2.55c-1.76 2.49-.1 7.15 3.53 8.2c6.14 1.79 7.21 4 8.46 5.79c1.51 2.1 2.94 4.73 5.49 5.14s4-1.51 3.89-5.21c-.02-5.92-3.11-11.4-8.17-14.47z" fill="%23ff847a"%3E%3C/path%3E%3C/svg%3E');
  background-size: 100%;
  opacity: 85%;
  background-repeat: no-repeat;
  background-color: #ffb30000;
  border: none;
  width: 150px;
  height: 150px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  font-weight: 700;
  line-height: 1.2em;
  font-size: 0.7rem;
  color: #ffcf00;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.quickquote-text {
  margin-top: -30px;
}

.fab span {
  font-size: 1rem;
  color: white;
  text-shadow: 2px 1px #03a5b6;
}

.fab:hover {
  background-color: rgba(255, 255, 255, 0);
  color: #03a5b6;
}

.fab:hover span {
  color: #03a5b6;
  text-shadow: 1px 1px black;
}

/* 04d3ead2 */
