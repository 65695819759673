body {
  overflow-x: hidden;
}

.fab {
  color: aliceblue;
}

.cyclicgallery {
  color: aliceblue;
}

.testimonial-section {
  position: relative;
  padding-top: 10px;
  padding-bottom: 0;
  margin-bottom: -100px;
}




