@import url("https://fonts.googleapis.com/css2?family=Modak&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Modak&display=swap');

.overlay {
  background-image: linear-gradient(
    135deg,
    rgba(3, 165, 182, 0.65),
    rgba(3, 165, 182, 0.65),
    rgba(3, 165, 182, 0.65),
    rgba(3, 165, 182, 0.65),
    rgba(255, 178, 0, 0.65),
    rgba(255, 207, 0, 0.65)
  );
  width: 100%;
  display: flex;
  padding: 30px;
  padding-top: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.overlay p {
  font-style: italic;
  text-align: center;
  rotate: -6deg;
  color: white;
  font-size: 30px;
  font-family: alegreya;
}

.header {
  height: 60%;
  width: 100%;
  background-size: cover;
  padding: 0;
  position: relative;
  background-position: center;
  background-image: url(../../app/assets/img/theme-waterfall.jpg);
}

.headline {
  font-family: modak;
  text-shadow: 1px 1px 1px black;
  color: #ffb200;
  font-size: 80px;
  font-style: italic;
  text-align: center;
  rotate: -6deg;
}

.header-btn-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.booking-btn {
  box-shadow: 1px 1px 10px #004ea8b9;
  color: white;
  text-shadow: 2px 1px 10px #004ea860;
  font-size: 1rem;
  font-weight: bold;
  border: 2px solid #ffcf00;
  width: 100%;
  background-color: #04d3eaf4;
  border-radius: 5px;
  font-family: alegreya;
}
.booking-btn:hover {
  color: #03a5b6;
  background-color: white;
}

.decor-btn {
  box-shadow: 1px 1px 10px #004ea8b9;
  color: white;
  text-shadow: 2px 1px 10px #004ea860;
  font-size: 1rem;
  font-weight: bold;
  border: 2px solid #03a5b6;
  width: 100%;
  background-color: #ffb200;
  border-radius: 5px;
  font-family: alegreya;
  margin-right: 5px;
}
.decor-btn:hover {
  color: #ffb200;
  background-color: white;
}
