.site-footer{
    background-color: #ffb200;
    padding-top: 20px;
    font-family: alegreya;
}

.email{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #03a5b6;
    font-weight: bold;
}
 a:link{
    text-decoration: none;
}

li{
    color: #03a5b6;
}

.link{
    color: black;
}


.col{
    display: flex;
    flex-direction: column;
    align-items: center;
}

