.header-overlay-businesscard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-image-businesscard {
  border-radius: 50%;
  border: 2px solid #ccfc00;
  max-height: 200px;
}

.page-content-businesscard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
}

.flex-container-businesscard > div > a {
  color: #03a5b6;
}



.flex-container-businesscard {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.flex-container-businesscard div {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.flex-container-businesscard a {
  color: inherit;
  text-decoration: none;
}
.contact-info-businesscard,
.link-businesscard a {
  font-family: alegreya;
  color: #03a5b6;
}
